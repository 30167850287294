import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import Selector from "./../../../../helpers/Selector"
import LoadingSpinner from "./../../../../helpers/LoadingSpinner"
import { useContext, useEffect, useState } from "react"
import { SeriesContext } from "../../../../context/SeriesContext"
import i18n from "../../../../i18n"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css";
import { showLastOptions, dateColumnsOptions, outputFormatOptions, PYTHON_API_URL } from "../../../../utils"
import axios from "axios"
import fileDownload from "js-file-download"
import { toast } from "react-toastify"
import { errorMessage, handleError } from "../../../../errors"
import DraggableRow from "../../../../helpers/DraggableRow"

var quarterOfYear = require("dayjs/plugin/quarterOfYear")
dayjs.extend(quarterOfYear)

const DataSelection = () => {

    const { t } = useTranslation()

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(new Date())
    const [includePreliminary, setIncludePreliminary] = useState(false)
    const [selectedDateColumns, setSelectedDateColumns] = useState(dateColumnsOptions[i18n.language]?.slice(0, 4))

    useEffect(() => {
        setStartDate(new Date(dayjs().subtract(5, "y")))
        setEndDate(new Date())
        // eslint-disable-next-line
    }, [])

    const [selectDateColumnRed, setSelectDateColumnRed] = useState(false)
    const [outputFormat, setOutputFormat] = useState(outputFormatOptions?.[i18n.language]?.[0])

    const [isDownloadEmpty, setIsDownloadEmpty] = useState(false)

    // const [plotData, setPlotData] = useState({})

    const [maxOrder, setMaxOrder] = useState(null)


    const {
        group,
        setGroup,

        groups,
        loadingGroups,

        frequencies,
        loadingFrequencies,


        fields,

        selectedFields,
        setSelectedFields,

        selectedFieldsOrder,
        setSelectedFieldsOrder,


        fieldsValues,
        loadingFieldsValues,

        lastUpdateDate,
        setLastUpdateDate,

        frequencyCount,

        selectedFrequencies,
        setSelectedFrequencies

    } = useContext(SeriesContext)

    const [submittingDownload, setSubmittingDownload] = useState(false)
    // const [submittingPlot, setSubmittingPlot] = useState(false)

    const [keyGroup, setKeyGroup] = useState(Math.random())

    const [selectorValues, setSelectorValues] = useState({})



    // const plot = async () => {
    //     const data = {
    //         selectedFields: {
    //             groupID: group,
    //             selectedFields: Object.values(selectedFields).map(
    //                 field => {

    //                     const curOrder = selectedFieldsOrder.find(
    //                         item => item.field === field.field && !item.isBlocked
    //                     )

    //                     return {
    //                         ...field,
    //                         order: curOrder ? curOrder?.order : -1
    //                     }
    //                 }
    //             )
    //         },
    //         startDate: startDate,
    //         endDate: endDate,
    //         updateDate: lastUpdateDate,
    //         includePreliminary: includePreliminary,
    //         frequency: selectedFrequencies?.[0]?.value

    //     }

    //     try {
    //         setSubmittingPlot(true)


    //         const resp = await axios.post(`${API_URL}/series/plot`, data)


    //         setPlotData(resp.data.data)


    //     } catch (error) {
    //         toast.error(errorMessage("sw:::000"))
    //     }
    //     setSubmittingPlot(false)
    // }


    const downloadData = async () => {

        // Check if at least one data column is selected
        if (selectedDateColumns?.length === 0) {
            setSelectDateColumnRed(true)
            return toast.error(errorMessage("se:::028"))
        }

        if ((outputFormat.value === "flat") && (selectedFrequencies.length !== 1)) {
            return toast.error(errorMessage("se:::029"))
        }

        if (filteredFrequencyCount > 5000) {
            return toast.error(errorMessage("se:::030"))
        }
        const data = {

            groupId: group,
            selectedFieldsColumn: Object.values(selectedFields).map(
                field => {

                    const curOrder = selectedFieldsOrder.find(
                        item => item.field === field.field && !item.isBlocked
                    )

                    return {
                        ...field,
                        isUnmerged: curOrder ? curOrder?.isUnmerged : false,
                        order: curOrder ? curOrder?.order : -1
                    }
                }
            ),

            selectedFieldsRow: [],
            startDate: startDate,
            endDate: endDate,
            updateDate: lastUpdateDate,
            includePreliminary: includePreliminary,
            downloadEmpty: isDownloadEmpty,
            frequencies: selectedFrequencies?.map(frequency => frequency.value),
            dateColumns: selectedDateColumns?.map(dateColumn => dateColumn.value),
            outputFormat: outputFormat.value

        }



        try {
            setSubmittingDownload(true)

            const curGroup = groups?.find(gr => gr?.id === group)

            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
            }

            const resp = await axios.post(
                `${PYTHON_API_URL}/restricted/statistics/download`,
                data,
                config
            )

            var filename
            switch (outputFormat.value) {
                case "standard":
                    filename = `Result ${curGroup?.nameEn}.xlsx`
                    break
                case "flat":
                    filename = `Result ${curGroup?.nameEn}.csv`
                    break
                default:
                    filename = `Result ${curGroup?.nameEn}.xlsx`
            }

            fileDownload(resp.data, filename)
        } catch (error) {
            return handleError(error)
        }
        setSubmittingDownload(false)
    }

    const [dragId, setDragId] = useState(null);

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev) => {
        const dragField = selectedFieldsOrder.find((field) => field.id === dragId);
        const dropField = selectedFieldsOrder.find((field) => field.id === ev.currentTarget.id);

        const dragBoxOrder = dragField.order;
        const dropBoxOrder = dropField.order;

        const update = dragBoxOrder >= dropBoxOrder ? +1 : -1

        const newFieldsState = selectedFieldsOrder.map((box) => {
            if (box.id === dragId) {
                box.order = dropBoxOrder;
            } if (box.id === ev.currentTarget.id) {
                box.order = box.order + update
            } else if ((box.order < Math.max(dragBoxOrder, dropBoxOrder)) && (box.order > Math.min(dragBoxOrder, dropBoxOrder))) {
                box.order = box.order + update
            }
            return box;
        });

        setSelectedFieldsOrder(newFieldsState);
    };

    const handleBlock = (id) => {
        setSelectedFieldsOrder(
            selectedFieldsOrder.map(
                item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isBlocked: !item.isBlocked,
                            isUnmerged: true
                        }
                    }
                    return item;
                }
            )
        );
    };


    const handleUnmerge = (id) => {
        setSelectedFieldsOrder(
            selectedFieldsOrder.map(
                item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isUnmerged: !item.isUnmerged,
                        }
                    }
                    return item;
                }
            )
        );
    };

    const clearForm = () => {
        setGroup(null)
        setSelectedFields({})
        setStartDate(new Date(dayjs().subtract(5, "y")))
        setEndDate(new Date())
        setLastUpdateDate(null)
        setIncludePreliminary(false)
        setKeyGroup(Math.random())
    }

    const filteredFrequencyCount = selectedFrequencies.length === 0 ?
        Object.values(frequencyCount).reduce((a, b) => a + b, 0) :
        selectedFrequencies.reduce((total, frequency) => total + (frequencyCount[frequency.value] || 0), 0);

    return (
        <>

            <div className="columns is-centered">
                <div className="column is-half">
                    <div className="content has-text-centered">
                        <h3>{t("series.group")}</h3>
                    </div>
                    <Selector
                        options={groups?.map(
                            group => (
                                {
                                    value: group.id,
                                    label: i18n.language === "en" ?
                                        group.nameEn :
                                        group.nameRu,
                                    ...group
                                }
                            )
                        )}
                        placeholder={t('series.selectDataType')}
                        key={keyGroup}
                        isLoading={loadingGroups}
                        onChange={target => {
                            setGroup(target.value)
                        }}

                    />
                </div>
            </div>



            {
                loadingGroups ?
                    <LoadingSpinner /> :
                    group !== null ?
                        <>



                            <div className="columns is-centered">
                                <div className="column">
                                    <div className="section">

                                        <div className="content has-text-centered">
                                            <h3>{t("series.selection")}</h3>
                                        </div>
                                        {
                                            fields?.filter(item => !item?.isHiddenInSearch)?.map(
                                                field => {

                                                    const isFieldAllSelected = selectedFields?.[field?.nameData]?.isAllSelected

                                                    return (
                                                        <div className="columns">
                                                            <div className="column is-one-quarter">
                                                                <div className="label has-text-centered">{
                                                                    i18n.language === "en" ?
                                                                        field.nameEn :
                                                                        field.nameRu
                                                                }</div>
                                                            </div>
                                                            <div className="column is-narrow">
                                                                <i className="fa fa-question fa-lg" title={
                                                                    i18n.language === "en" ?
                                                                        field.descriptionEn :
                                                                        field.descriptionRu
                                                                }></i>
                                                            </div>
                                                            <div className="column">

                                                                <div className="field has-text-centered">
                                                                    <div className="control">


                                                                        <Selector
                                                                            isMulti={true}
                                                                            key={field.id}
                                                                            isSearchable={true}
                                                                            isDisabled={isFieldAllSelected}
                                                                            value={selectorValues?.[field.id]}
                                                                            isOptionDisabled={() => loadingFieldsValues}
                                                                            placeholder={isFieldAllSelected ? t("series.allSelectedPlaceholder") : ""}
                                                                            options={fieldsValues?.[field.id]?.map(
                                                                                value => (
                                                                                    {
                                                                                        value: value,
                                                                                        label: value

                                                                                    }
                                                                                )
                                                                            )}

                                                                            onMenuOpen={() => {
                                                                                const otherSelectedFields = Object.keys(selectedFields).reduce((acc, key) => {
                                                                                    acc[key] = { ...selectedFields[key], isActive: key === field.nameData };
                                                                                    return acc;
                                                                                }, {});

                                                                                setSelectedFields(otherSelectedFields);
                                                                            }}


                                                                            onChange={targets => {
                                                                                setSelectorValues(selectorValues => ({ ...selectorValues, [field.id]: targets }))
                                                                                const curSelectorValues = targets.map(item => item.value);

                                                                                const otherSelectedFields = Object.keys(selectedFields).reduce((acc, key) => {
                                                                                    if (key !== field.nameData) {
                                                                                        acc[key] = { ...selectedFields[key], isActive: false };
                                                                                    } else {
                                                                                        acc[key] = { ...selectedFields[key], isActive: true };
                                                                                    }
                                                                                    return acc;
                                                                                }, {});


                                                                                const updatedSelectedFields = { ...otherSelectedFields };

                                                                                if (curSelectorValues.length > 0) {
                                                                                    updatedSelectedFields[field.nameData] = {
                                                                                        field: field.nameData,
                                                                                        isActive: true,
                                                                                        isAlwaysSelected: field.isAlwaysSelected,
                                                                                        isUnmerged: field.isUnmerged,
                                                                                        values: curSelectorValues,
                                                                                        isAllSelected: false,

                                                                                    };

                                                                                    const updatedSelectedFieldsOrder = selectedFieldsOrder.map(
                                                                                        item => {
                                                                                            if (item.id === field.id) {
                                                                                                return {
                                                                                                    ...item,
                                                                                                    isBlockable: !item.isAlwaysSelected,
                                                                                                }
                                                                                            }
                                                                                            return item;
                                                                                        }
                                                                                    )
                                                                                    const fieldOrder = updatedSelectedFieldsOrder.find(item => item.id === field.id);

                                                                                    if (!fieldOrder && !field.isHiddenInOrder) {
                                                                                        updatedSelectedFieldsOrder.push({
                                                                                            id: field.id,
                                                                                            field: field.nameData,
                                                                                            isAlwaysSelected: field.isAlwaysSelected,
                                                                                            isHiddenInOrder: field.isHiddenInOrder,
                                                                                            isUnmerged: field.isUnmerged,
                                                                                            isBlockable: !field.isAlwaysSelected,
                                                                                            order: maxOrder + 1,
                                                                                            isBlocked: false,
                                                                                            title: i18n.language === "en" ? field.nameEn : field.nameRu,

                                                                                        });
                                                                                    }

                                                                                    setSelectedFieldsOrder(updatedSelectedFieldsOrder);
                                                                                    setMaxOrder(maxOrder + 1)


                                                                                } else {

                                                                                    if (!field?.isAlwaysSelected) {
                                                                                        delete updatedSelectedFields[field.nameData];
                                                                                    }

                                                                                    const updatedSelectedFieldsOrder = selectedFieldsOrder.map(
                                                                                        item => {
                                                                                            if (item.id === field.id) {
                                                                                                return {
                                                                                                    ...item,
                                                                                                    isBlockable: !item.isAlwaysSelected

                                                                                                }
                                                                                            }
                                                                                            return item;
                                                                                        }
                                                                                    )

                                                                                    setSelectedFieldsOrder(
                                                                                        updatedSelectedFieldsOrder.filter(item => item.id !== field.id || item.isAlwaysSelected)
                                                                                    );
                                                                                }

                                                                                setSelectedFields(updatedSelectedFields);
                                                                            }}



                                                                            isLoading={loadingFieldsValues}

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column is-narrow">
                                                                <button
                                                                    className={`button ${isFieldAllSelected ? "is-success" : ""}`}
                                                                    disabled={(selectorValues?.[field.id]?.length > 0) || loadingFieldsValues}
                                                                    onClick={
                                                                        () => {

                                                                            const otherSelectedFields = Object.keys(selectedFields).reduce((acc, key) => {
                                                                                if (key !== field.nameData) {
                                                                                    acc[key] = { ...selectedFields[key], isActive: false };
                                                                                } else {
                                                                                    acc[key] = { ...selectedFields[key], isActive: true };
                                                                                }
                                                                                return acc;
                                                                            }, {});


                                                                            var updatedSelectedFields = { ...otherSelectedFields };

                                                                            selectorValues[field.id] = []

                                                                            updatedSelectedFields[field.nameData] = {
                                                                                field: field.nameData,
                                                                                isActive: true,
                                                                                isAlwaysSelected: field.isAlwaysSelected,
                                                                                isUnmerged: field.isUnmerged,
                                                                                isAllSelected: !isFieldAllSelected,
                                                                                values: [],
                                                                            };


                                                                            const updatedSelectedFieldsOrder = selectedFieldsOrder

                                                                            const fieldOrder = updatedSelectedFieldsOrder.find(item => item.id === field.id);



                                                                            if (!fieldOrder && !field.isHiddenInOrder) {
                                                                                updatedSelectedFieldsOrder.push({
                                                                                    id: field.id,
                                                                                    field: field.nameData,
                                                                                    isAlwaysSelected: field.isAlwaysSelected,
                                                                                    isHiddenInOrder: field.isHiddenInOrder,
                                                                                    isUnmerged: field.isUnmerged,
                                                                                    isBlockable: !field.isAlwaysSelected,
                                                                                    order: maxOrder + 1,
                                                                                    isBlocked: false,
                                                                                    title: i18n.language === "en" ? field.nameEn : field.nameRu,

                                                                                });
                                                                                setSelectedFieldsOrder(updatedSelectedFieldsOrder);
                                                                                setMaxOrder(maxOrder + 1)
                                                                            } else {
                                                                                if (!field?.isAlwaysSelected) {
                                                                                    delete updatedSelectedFields[field.nameData];
                                                                                }

                                                                                const updatedSelectedFieldsOrder = selectedFieldsOrder

                                                                                setSelectedFieldsOrder(
                                                                                    updatedSelectedFieldsOrder.filter(item => item.id !== field.id || item.isAlwaysSelected)
                                                                                );
                                                                            }



                                                                            setSelectedFields(updatedSelectedFields);
                                                                        }
                                                                    }
                                                                >
                                                                    {t("series.selectAll")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )

                                                }
                                            )
                                        }

                                        <div className="columns is-centered">
                                            <div className="column has-text-centered is-3">
                                                <div className="label has-text-grey">
                                                    {`${t("series.totalCount")}${filteredFrequencyCount}`}
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="section">

                                        <div className="columns is-centered">
                                            <div className="column is-10">

                                                <div className="content has-text-centered">
                                                    <h3> {t("searchFields.order")}</h3>
                                                </div>

                                                {selectedFieldsOrder
                                                    .sort((a, b) => a.order - b.order)
                                                    .map((field) => {


                                                        const curSelectedField = fields?.find(item => item?.id === field?.id)

                                                        return <>
                                                            <div className="columns is-left">
                                                                <div className="column is-8">
                                                                    <DraggableRow
                                                                        key={field.order}
                                                                        id={field.id}

                                                                        onDelete
                                                                        title={field.title}
                                                                        handleDrag={handleDrag}
                                                                        handleDrop={handleDrop}
                                                                        isBlocked={false}
                                                                    />

                                                                </div>


                                                                <div className="column is-narrow">
                                                                    <button
                                                                        className={`button ${field.isBlocked ? "is-success" : ""}`}
                                                                        disabled={curSelectedField?.isAlwaysSelected}
                                                                        onClick={() => {
                                                                            handleBlock(field.id)
                                                                        }}
                                                                    >
                                                                        Mute
                                                                    </button>
                                                                </div>
                                                                <div className="column is-narrow">
                                                                    <button
                                                                        className={`button ${!field.isUnmerged ? "is-success" : ""}`}
                                                                        disabled={field.isBlocked}
                                                                        onClick={() => {
                                                                            handleUnmerge(field.id)
                                                                        }}
                                                                    >
                                                                        Merge
                                                                    </button>
                                                                </div>
                                                            </div>



                                                        </>
                                                    }
                                                    )}

                                            </div>
                                        </div>
                                    </div>


                                    <div className="section">


                                        <div className="content has-text-centered">
                                            <h3>{t("series.outputSettings")}</h3>
                                        </div>
                                        <div className="columns">

                                            <div className="column has-text-centered">


                                                <div className="columns mb-0">
                                                    <div className="column">
                                                        <label className="label">{t("series.outputFormat")}</label>
                                                    </div>
                                                </div>
                                                <Selector

                                                    options={outputFormatOptions?.[i18n?.language]}
                                                    onChange={target => setOutputFormat(target)}
                                                    value={outputFormat}

                                                />

                                            </div>

                                            <div className="column has-text-centered">

                                                <div className="columns is-centered mb-0">
                                                    <div className="column is-narrow">
                                                        <label className="label">{t("series.frequencies")}</label>
                                                    </div>
                                                    <div className="column mb-0 is-narrow">
                                                        <i className="fa fa-question fa-lg" title={t("series.frequenciesInfo")}></i>
                                                    </div>
                                                </div>
                                                <Selector

                                                    options={Object.keys(frequencyCount)?.sort(
                                                        (a, b) => frequencies[a] - frequencies[b]
                                                    )?.map(
                                                        item => ({
                                                            label: item,
                                                            value: item
                                                        })
                                                    )}


                                                    isMulti={true}
                                                    onChange={targets => {
                                                        setSelectedFrequencies(targets)
                                                    }}
                                                    isLoading={loadingFieldsValues || loadingFrequencies}

                                                />
                                            </div>
                                            <div className="column has-text-centered">

                                                <div className="columns mb-0">
                                                    <div className="column">
                                                        <label className="label">{t("series.showLast")}</label>

                                                    </div>
                                                </div>
                                                <Selector
                                                    options={showLastOptions?.[i18n?.language]}
                                                    placeholder={t("series.showLast")}
                                                    defaultValue={showLastOptions?.[i18n?.language]?.[4]}
                                                    onChange={target => {
                                                        switch (target.value) {
                                                            case "A":
                                                                setStartDate(null)
                                                                setEndDate(new Date())
                                                                break
                                                            case "5y":
                                                                setStartDate(new Date(dayjs().subtract(5, "y")))
                                                                setEndDate(new Date())
                                                                break
                                                            case "10y":
                                                                setStartDate(new Date(dayjs().subtract(10, "y")))
                                                                setEndDate(new Date())
                                                                break
                                                            default:
                                                                setStartDate(
                                                                    new Date(dayjs().subtract(1, target.value))
                                                                )
                                                                setEndDate(new Date())
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="column">

                                                <div className="columns mb-0">
                                                    <div className="column">
                                                        <label className="label has-text-centered">{t("series.includePreliminary")}</label>
                                                    </div>
                                                </div>
                                                <button
                                                    className={`button is-fullwidth ${includePreliminary ? "is-success" : ""}`}
                                                    onClick={() => setIncludePreliminary(!includePreliminary)}
                                                >
                                                    {includePreliminary ? t("series.yes") : t("series.no")}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="columns is-centered">

                                            <div className="column has-text-centered">

                                                <div className="columns mb-0">
                                                    <div className="column">
                                                        <label className="label">{t("series.startDate")}</label>
                                                    </div>
                                                </div>
                                                <DatePicker
                                                    className="input is-fullwidth"
                                                    selected={startDate}
                                                    dateFormat="dd.MM.yyyy"
                                                    onChange={date => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    maxDate={endDate}
                                                    isClearable={true}
                                                    showYearDropdown
                                                />
                                            </div>


                                            <div className="column has-text-centered">

                                                <div className="columns mb-0">
                                                    <div className="column">
                                                        <label className="label">{t("series.endDate")}</label>

                                                    </div>
                                                </div>
                                                <DatePicker
                                                    className="input"
                                                    selected={endDate}
                                                    // placeholderText={t("series.endDate")}
                                                    dateFormat="dd.MM.yyyy"
                                                    onChange={date => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    isClearable={true}
                                                    showYearDropdown
                                                />

                                            </div>

                                            <div className="column has-text-centered">
                                                <div className="columns mb-0">
                                                    <div className="column mb-0">
                                                        <label className="label">{t("series.updateDate")} <i className="fa fa-question fa-lg" title={t("series.lastUpdateInfo")} /></label>

                                                    </div>

                                                </div>
                                                <DatePicker
                                                    className="input is-fullwidth mt-0"
                                                    selected={lastUpdateDate}
                                                    dateFormat="dd.MM.yyyy"
                                                    onChange={date => setLastUpdateDate(date)}
                                                    selectsEnd
                                                    startDate={endDate}
                                                    endDate={endDate}
                                                    maxDate={endDate}
                                                    isClearable={true}
                                                    showYearDropdown
                                                />


                                            </div>
                                            <div className="column">

                                                <div className="columns mb-0">
                                                    <div className="column">
                                                        <label className="label has-text-centered">{t("series.isDownloadEmpty")}</label>
                                                    </div>
                                                </div>
                                                <button
                                                    className={`button is-fullwidth ${isDownloadEmpty ? "is-success" : ""}`}
                                                    onClick={() => setIsDownloadEmpty(!isDownloadEmpty)}
                                                >
                                                    {isDownloadEmpty ? t("series.yes") : t("series.no")}
                                                </button>
                                            </div>

                                        </div>


                                        <div className="columns is-centered">
                                            <div className="column is-half has-text-centered">
                                                <label className="label">{t("series.dateColumns")}</label>
                                                <Selector

                                                    options={dateColumnsOptions?.[i18n.language]}
                                                    isMulti={true}
                                                    defaultValue={dateColumnsOptions?.[i18n.language]?.slice(0, 4)}
                                                    isRed={selectDateColumnRed}
                                                    onFocus={() => setSelectDateColumnRed(false)}
                                                    onChange={targets => {
                                                        setSelectedDateColumns(targets)
                                                    }}

                                                />

                                            </div>
                                        </div>



                                        <div className="columns is-centered">
                                            <div className="column is-one-quarter">
                                                <div className="field">
                                                    <div className="control">
                                                        <button
                                                            className="button is-danger is-fullwidth"
                                                            onClick={clearForm}
                                                        >
                                                            {t("series.clearForm")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="column is-one-quarter">
                                        <div className="field">
                                            <div className="control">
                                                <button
                                                    className="button is-info is-fullwidth"
                                                    onClick={plot}
                                                >
                                                    {t("series.plot")}
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}



                                            <div className="column is-one-quarter">
                                                <div className="field">
                                                    <div className="control">
                                                        <button
                                                            className={`button is-link is-fullwidth ${submittingDownload ? "is-loading" : ""}`}
                                                            onClick={downloadData}
                                                        >
                                                            {t("series.download")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div >
                            </div>


                        </>
                        : null

            }



        </>
    )
}

export default DataSelection