
import { useContext } from "react"
import { SeriesContext } from "../../../../../context/SeriesContext"
import UploadTask from "./UploadTask"

const UploadTasksList = () => {


    const { uploadTasks } = useContext(SeriesContext)

    return (Object.keys(uploadTasks).length > 0) ?
        <div className="box upload-tasks-box">

            {Object.keys(uploadTasks).map(id => {
                return <UploadTask task={uploadTasks[id]} />
            })}
        </div> :
        null

}

export default UploadTasksList