import { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleError } from "../../../../../errors";

import axios from "axios"
import { PYTHON_API_URL } from "../../../../../utils";
import LoadingSpinner from "../../../../../helpers/LoadingSpinner";

const statusToColor = {
    "pending": "is-info",
    "active": "is-link",
    "completed": "is-success",
    "failed": "is-danger",
    "partial": "is-primary",
    "cancelled": ""
}
function formatTimes(elapsedSeconds, totalSeconds, status) {
    // Calculate hours, minutes, and seconds for both times
    const elapsedHrs = Math.floor(elapsedSeconds / 3600);
    const elapsedMins = Math.floor((elapsedSeconds % 3600) / 60);
    const elapsedSecs = elapsedSeconds % 60;

    const totalHrs = Math.floor(totalSeconds / 3600);
    const totalMins = Math.floor((totalSeconds % 3600) / 60);
    const totalSecs = totalSeconds % 60;

    // Determine if we need to show minutes and hours based on total time
    const showHours = totalHrs > 0;
    const showMinutes = showHours || totalMins > 0;

    // Format elapsed time
    const formattedElapsedHrs = showHours ? `${elapsedHrs.toString().padStart(2, '0')}:` : '';
    const formattedElapsedMins = showMinutes ? `${elapsedMins.toString().padStart(2, '0')}:` : '';
    const formattedElapsedSecs = elapsedSecs.toString().padStart(2, '0');

    // Format total time
    const formattedTotalHrs = showHours ? `${totalHrs.toString().padStart(2, '0')}:` : '';
    const formattedTotalMins = showMinutes ? `${totalMins.toString().padStart(2, '0')}:` : '';
    const formattedTotalSecs = totalSecs.toString().padStart(2, '0');

    const formattedElapsedTime = `${formattedElapsedHrs}${formattedElapsedMins}${formattedElapsedSecs}`;
    const formattedTotalTime = `${formattedTotalHrs}${formattedTotalMins}${formattedTotalSecs}`;

    return `${formattedElapsedTime} / ${formattedTotalTime}`
}


const UploadTask = ({ task }) => {

    const { t } = useTranslation()

    const [log, setLog] = useState("")
    const [loadingLog, setLoadingLog] = useState(true)
    const [showLog, setShowLog] = useState(false)

    const loadLog = async id => {

        setLoadingLog(true)
        try {

            const resp = await axios.get(`${PYTHON_API_URL}/restricted/statistics/upload/task/log?taskId=${id}`)
            const { log } = resp.data

            setLog(log)

        } catch (error) {
            handleError(error)
        }
        setLoadingLog(false)
    }



    const LogSection = () => {

        const message = loadingLog ? <LoadingSpinner /> : (
            <div className="content">
                {log?.split("\n").map(function (item, idx) {
                    return (
                        <span key={idx}>
                            {item}
                            <br />
                        </span>
                    )
                })}
            </div>
        )

        return message


    }

    const Box = <div className="box">
        <div className="columns is-vcentered">

            <div className="column">
                <div className="columns is-vcentered">

                    <div className="column is-3">
                        {task.userEmail}
                    </div>
                    <div className="column is-centered">
                        <progress class="progress is-success" value={`${task.percentage * 100}`} max="100">
                            {task.percentage * 100}%
                        </progress>
                    </div>

                    <div className="column has-text-centered is-2">
                        {formatTimes(task.timeElapsed, task.timeEstimated, task.status)}

                    </div>

                    <div className="column is-centered is-2">
                        <span className={`button is-small is-fullwidth is-active is-rounded ${statusToColor[task.status]}`}>{task.status}</span>
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className="column is-narrow">{t("tasks.uploadedFile")}: <b>{task?.filename}</b></div>
                    <div className="column"></div>
                    <div className="column is-2">
                        <button
                            className="button is-small is-fullwidth is-centered"
                            onClick={() => {
                                loadLog(task.id)
                                setShowLog(!showLog)
                            }

                            }
                        >
                            <span className="icon is-small">
                                <i className={`fa fa-angle-${showLog ? "up" : "down"}`}></i>

                            </span>

                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {t('tasks.showLog')}


                        </button>

                    </div>


                </div>


            </div>
        </div>


        {showLog ? <LogSection /> : null}

    </div >
    return Box
}

export default UploadTask