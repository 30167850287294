import dayjs from "dayjs"
import axios from "axios"
import { PYTHON_API_URL } from "../../../utils"
import LoadingSpinner from "../../../helpers/LoadingSpinner"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { InvitesContext } from "../../../context/InvitesContext"
import { toast } from "react-toastify"
import { errorMessage } from "../../../errors"

const InviteList = () => {
  const { loading, invites, setNeedRefetch, isActive } =
    useContext(InvitesContext)
  const { t } = useTranslation()

  const deleteInvite = async inviteId => {

    try {

      await axios.delete(`${PYTHON_API_URL}/restricted/invite?id=${inviteId}`)

    } catch (error) {

      if (error?.response) {
        const { message } = error?.response?.data
        toast.error(errorMessage(message))
      } else {
        console.log(error)
        toast.error(errorMessage())
      }
    }

    setNeedRefetch(true)


  }

  const invitesTable = (
    <div className="box">
      {invites.length ? (
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>{t("auth.email")}</th>
                <th>{t("invite.created")}</th>
                {isActive ? <th>{t("invite.expired")}</th> : <></>}
              </tr>
            </thead>
            <tbody>
              {invites.map(invite => (
                <tr key={invite.id}>
                  <th>{invite.email}</th>
                  <td>
                    {dayjs(invite.creationDate).format("DD/MM/YYYY - HH:mm")}
                  </td>

                  {isActive ? (
                    <td>
                      {dayjs(invite.expirationDate).format(
                        "DD/MM/YYYY - HH:mm"
                      )}
                    </td>
                  ) : (
                    <></>
                  )}

                  {invite.isActive ? (
                    <td>
                      <button
                        className="delete is-medium"
                        onClick={() => {
                          deleteInvite(invite?.id)
                        }}
                      ></button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="has-text-centered has-text-grey"> {t("listEmpty")} </p>
      )}
    </div>
  )

  return loading ? <LoadingSpinner /> : invitesTable
}

export default InviteList
