import axios from "axios"
import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import { handleError } from "../errors"
import i18n from "../i18n"

const useSearchFields = () => {
  const [group, setGroup] = useState(null)

  const [fields, setFields] = useState([])
  const [updatedFields, setUpdatedFields] = useState([])

  const [loadingFields, setLoadingFields] = useState(false)
  const [needRefetchFields, setNeedRefetchFields] = useState(false)

  const [selectedFields, setSelectedFields] = useState({})
  const [selectedFieldsOrder, setSelectedFieldsOrder] = useState([])





  const fetchFields = async () => {
    try {
      const resp = await axios.get(`${PYTHON_API_URL}/restricted/fields/list?id=${group}`)

      const { fields: fieldsList } = resp.data

      setFields(fieldsList)
      setSelectedFields(
        fieldsList?.filter(field => field.isAlwaysSelected).reduce(
          (acc, field) => {

            acc[field.nameData] = {
              field: field.nameData,
              isActive: false,
              isAlwaysSelected: field.isAlwaysSelected,
              isHiddenInOrder: field.isHiddenInOrder,
              isUnmerged: field.isUnmerged,
              isAllSelected: false,
              values: [],
            }

            return acc
          }, {})
      )
      setUpdatedFields(fieldsList)
      setLoadingFields(false)
      setNeedRefetchFields(false)
    } catch (error) {
      setLoadingFields(false)
      return handleError(error)
    }
  }

  useEffect(() => {
    if (!group) return
    setLoadingFields(true)
    fetchFields()
    // eslint-disable-next-line
  }, [group])

  useEffect(() => {
    if (needRefetchFields) {
      if (!group) return
      setLoadingFields(true)
      fetchFields()
    }
    // eslint-disable-next-line
  }, [needRefetchFields])

  useEffect(() => {
    if (!fields || !group) return
    const alwaysSelectedOrders = Object.values(fields).filter(
      field => field?.isAlwaysSelected
    ).map(
      (field, idx) => {
        return ({
          id: field?.id,
          field: field?.nameData,
          order: 100 + idx,
          isAlwaysSelected: field?.isAlwaysSelected,
          isHiddenInOrder: field?.isHiddenInOrder,
          isUnmerged: field?.isUnmerged,
          isDeletable: false,
          title: i18n.language === "en" ? field?.nameEn : field?.nameRu,
        })
      }
    )

    setSelectedFieldsOrder(alwaysSelectedOrders)
    // eslint-disable-next-line
  }, [fields])


  const [fieldsValues, setFieldsValues] = useState({})

  const [selectedFrequencies, setSelectedFrequencies] = useState([])


  const [loadingFieldsValues, setLoadingFieldsValues] = useState(false)
  const [needRefetchFieldsValues, setNeedRefetchFieldsValues] = useState(false)
  const [lastUpdateDate, setLastUpdateDate] = useState(null)

  const [frequencyCount, setFrequencyCount] = useState({})

  const fetchFieldsValues = async () => {
    try {

      const data = {
        groupID: group,
        selectedFields: Object.values(selectedFields).filter(
          field => (!(field.isAlwaysSelected && field.values.length === 0))
        ),
        lastUpdateDate
      }


      const resp = await axios.post(`${PYTHON_API_URL}/restricted/fields/values/list`, data)

      const { values, frequencyCount } = resp.data


      setFrequencyCount(frequencyCount)
      setFieldsValues(values)
      setLoadingFieldsValues(false)
      setNeedRefetchFieldsValues(false)
    } catch (error) {
      setLoadingFieldsValues(false)
      return handleError(error)
    }
  }

  useEffect(() => {
    if (!group) return
    setLoadingFieldsValues(true)
    fetchFieldsValues()
    // eslint-disable-next-line
  }, [group, selectedFields, lastUpdateDate])

  useEffect(() => {
    if (!group) return
    if (needRefetchFieldsValues) {
      setLoadingFieldsValues(true)
      fetchFieldsValues()
    }
    // eslint-disable-next-line
  }, [needRefetchFieldsValues])


  return {
    fields,
    loadingFields,
    setNeedRefetchFields,

    updatedFields,
    setUpdatedFields,

    fieldsValues,
    loadingFieldsValues,
    setNeedRefetchFieldsValues,

    group,
    setGroup,

    selectedFields,
    setSelectedFields,

    selectedFieldsOrder,
    setSelectedFieldsOrder,

    lastUpdateDate,
    setLastUpdateDate,

    frequencyCount,

    selectedFrequencies,
    setSelectedFrequencies,

  }
}

export default useSearchFields
